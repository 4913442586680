export default [
  {
    header: 'Administration',
  },
  {
    title: 'Users',
    route: 'administration-users',
    icon: 'UsersIcon',
  },
  {
    title: 'Settings',
    route: 'administration-settings',
    icon: 'SettingsIcon',
  }
]
