export default [
  {
    header: 'Loyalty Management',
  },
  {
    title: 'Transactions',
    route: 'loyalty-transactions',
    icon: 'MailIcon',
  },
  {
    title: 'Partner Transactions',
    route: 'loyalty-partner-transactions',
    icon: 'MailIcon',
  },
  {
    title: 'Customers',
    route: 'loyalty-customers',
    icon: 'CalendarIcon',
  },
  {
    title: 'Outlets',
    route: 'loyalty-outlets',
    icon: 'LayoutIcon',
  },
  {
    title: 'Billing',
    route: 'loyalty-billing',
    icon: 'DollarSignIcon',
  },
  {
    title: 'Vouchers',
    route: 'loyalty-vouchers',
    icon: 'CopyIcon',
  },
  {
    title: 'Rules Engine',
    route: 'loyalty-rules',
    icon: 'ShuffleIcon',
  },
  {
    title: 'Segments',
    route: 'customer-segmentation',
    icon: 'FilterIcon',
  },
  {
    title: 'Lottery',
    route: 'lottery',
    icon: 'CodesandboxIcon',
  },
  {
    title: 'Fraud Mgt',
    route: 'loyalty-fraud',
    icon: 'EyeIcon',
  }
]
