export default [
  {
    header: 'Loyalty Management',
  },
  {
    title: 'Transactions',
    route: 'loyalty-transactions',
    icon: 'MailIcon',
  },
  {
    title: 'Users',
    route: 'merchant-users',
    icon: 'UsersIcon',
  },
  {
    title: 'Invoices',
    route: 'merchant-invoices',
    icon: 'DollarSignIcon',
  },
  {
    title: 'Vouchers',
    route: 'merchant-vouchers',
    icon: 'FileIcon',
  },
]
