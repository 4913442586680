export default [
  {
    header: 'Reports',
  },
  {
    title: 'View All',
    route: 'view-all-reports',
  },
  {
    title: 'Exports',
    route: 'export-reports',
  }
]
