export default [
  {
    header: 'Campaign Management',
  },
 /* {
    title: 'Offers & Coupons',
    route: 'coming-soon',
    icon: 'TypeIcon',
  },
  {
    title: 'Raffles',
    route: 'coming-soon',
    icon: 'DropletIcon',
  },
  {
    title: 'Email',
    route: 'coming-soon',
    icon: 'EyeIcon',
  },*/
  {
    title: 'SMS',
    icon: 'CreditCardIcon',
    route: 'campaign-sms',
  },
  {
    title: 'Push Notifications',
    icon: 'ArchiveIcon',
    route: 'campaign-push-notification',
  }
]
